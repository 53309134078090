import React from "react";
import image_gabz from '../img/gabz.png';
import image_me from '../img/me.jpeg';
import image_arrow_home from '../img/arrow-home.png';

import buttonMinimize from '../img/button_minimize.png';
import buttonMaximize from '../img/button_maximize.png';
import buttonClose from '../img/button_close.png';
import Loading from "./Loading";
import NamesHolder from "./NamesHolder";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import HomeNavigationList from "./HomeNavigationList";
import DOMPurify from 'dompurify';

import { Link } from 'react-router-dom'
import DraggableWindow from "./DraggableWindow";
const About = ({visibility,functions,pageList,data,resetDrag}) => {
  const milliseconds = 1000;
    const [loadPage, setLoadPage] = useState(false);
    const imageAnimationDuration = 1;
    const title = "MyStory_M_W_Rakhudu_v8.txt";
   
    const showAboutSection = true//visibility.showAbout;
    const resetDragVar = resetDrag;


  //show intro
  function navigateIntro(showIntroSection) {
    console.log("Navigate To Intro")
  functions.navigateIntro();
  console.log(showIntroSection)

    
  }
    //fetch data
    const [content_data, setData] = useState(null);
    useEffect(() => {
      fetch('https://app.mrakhudu.com/endpoints/data/fetchAbout.php')
        .then(response => response.json())
        .then(content_data =>{ setData(content_data)
          console.log("Results: "+JSON.stringify(content_data))
          setLoadPage(true)
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);
    //parse data
    var content = "";
    try{
      content = content_data.map((item, index) => (
        item.biography
      ))
      }catch (ex){
        console.log("Error: "+ex)
      }


  return (
    <div >
      <div id="content" style={{display: showAboutSection ? 'block' : 'none' }}>
        <div className="pageTitle" id="aboutPageTitle">
          <h1>ABOUT</h1>
          <h4>EVERYTHING ABOUT ME</h4>
        </div>
        <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
        <div className="row">
        <div className="col-md-4 home-decor">
              
            </div>
            <div className="col-md-6">
              <DraggableWindow title={title} image={image_me} content={content} type = "about" resumeLink={""}
              loadPage={loadPage} resetDragVar={resetDragVar} imageAnimationDuration = {imageAnimationDuration}/>
            
            </div>
        </div>
      </div>
    </div>
  )
}

export default About