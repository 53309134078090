import React from 'react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
const DraggableWindow = ({title,image,content,loadPage,resetDragVar,imageAnimationDuration,type,resumeLink}) => {

    

    // Add state to determine if it's a touch device
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isMaximizedWindow, setMaximizeWindow] = useState(false);
    const [isMinimizedWindow, setMinimizeWindow] = useState(false);
    const [isClosedWindow, setCloseWindow] = useState(false);
    // Detect if it's a touch device
    useEffect(() => {
      const onTouchStart = () => setIsTouchDevice(true);
      const onHoverStart = () => setIsTouchDevice(false);
   
      document.addEventListener('touchstart', onTouchStart);
      document.addEventListener('mouseenter', onHoverStart);
      if (type == "resume"){
       minimizeindow()
      }
      return () => {
          document.removeEventListener('touchstart', onTouchStart);
          document.removeEventListener('mouseenter', onHoverStart);
      };
      }, []);
      function maximizeWindow (){
        setMaximizeWindow(!isMaximizedWindow)
        setMinimizeWindow(false)
      }
      function minimizeindow (){
        setMinimizeWindow(!isMinimizedWindow)
        setMaximizeWindow(false)
      }

     
  return (
    <motion.div
      className={isMaximizedWindow ? "window aboutBoxFull" : (isMinimizedWindow ? "window aboutBoxMinimized" : "window aboutBox")}
      style={{position: 'absolute',top: type == "resume" ? "65px" : (type == "education" ? "65px" : '20px'),
      maxWidth : type == "resume" ? "400px" : "auto", maxHeight : type == "resume" ? "170px" : "auto"
    }}
      dragConstraints={resetDragVar} animate={{ y: loadPage ? 0 : -500 , opacity: loadPage ? 1 : 0 }} 
      drag={!isTouchDevice} transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity:0 }} >  
            
      <div className="title-bar">
        <span className="title">{title}</span>
        <div className="window-controls">
          <button onClick={minimizeindow}>−</button>
          <button onClick={maximizeWindow}>□</button>
          <button><Link to="/" style={{textDecoration: "none", color: "black"}}>×</Link></button>
        </div>
      </div>
      {/*window for about page*/}
      <div style={{display : type =="about" ? "block" :"none" }}>
      <div className="aboutBoxContent row" style={{display : isMinimizedWindow == true ?  "none" : ""}} >
                <div className="col-md-4">
                      <img className="aboutBoxMyPhoto" src = {image}/>
                </div>
                <div className="aboutBoxContentText col-md-8">
                   <div dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                
            </div>
            <div >
            <div className="row aboutBoxWindowButtons" style={{display :  isMinimizedWindow == true ?  "none" : ""}} >
              <Link to = "/skills" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>What I Actually Do</motion.div>
                </Link>
                <Link to = "/education" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>My Education</motion.div>
                </Link>
                <Link to = "/portfolio" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>Here's The Evidence</motion.div>
                </Link>
                <Link to = "/contact" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>Let's Have A Chat </motion.div>
                </Link>
            </div>
            </div>
            <div >
            </div>
            </div>
            
{/*window for education page*/}
<div style={{display : type =="education" ? "block" :"none" }}>
      <div className="aboutBoxContent " style={{display : isMinimizedWindow == true ?  "none" : ""}} >
                
                <div className="aboutBoxContentText">
                   <div dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                
            </div>
            <div >
            <div className="row aboutBoxWindowButtons" style={{display :  isMinimizedWindow == true ?  "none" : ""}} >
              <Link to = "/skills" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>What I Actually Do</motion.div>
                </Link>
                <Link to = "/education" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>My Education</motion.div>
                </Link>
                <Link to = "/portfolio" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>Here's The Evidence</motion.div>
                </Link>
                <Link to = "/contact" className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>Let's Have A Chat </motion.div>
                </Link>
            </div>
            </div>
            <div >
            </div>
            </div>


            {/*window for resume page */}
            <div style={{display : type =="resume" ? "block" :"none" }}>
              <div className="aboutBoxContent row" style={{display : isMinimizedWindow == true ?  "none" : "", maxHeight: '50px'}} > 
                <div className="aboutBoxContentText col-md-12" style={{overflow: "hidden"}}>
                   <div dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                </div>
           
            <div className="row aboutBoxWindowButtons" style={{display : type =="resume" & isMinimizedWindow == true ?  "none" : ""}} >
              <Link to = {resumeLink} className="col-md-3" >
                <motion.div 
                whileHover={{ scale: 1.1, opacity: 0.6}}
                 whileTap={{
                  scale: 1.2,
                  borderRadius: "100%"
                }}>Download .pdf</motion.div>
                </Link>
               
            </div>
            </div>
    </motion.div>
  );
};

export default DraggableWindow;
