import { Outlet, Link,useLocation } from "react-router-dom";
import NavigationBar from "../NavigationBar"
import Background from '../Background';
import Header from '../Header';
const Layout =  ({logo,visibility,functions,pageList}) => {
  const location = useLocation();
  const currentPage = location.pathname; // Get the current pathname from the URL

  return (
    <>
       <Header logo={logo}  visibility={visibility} functions={{functions}} 
      pageList={pageList} />

      <Outlet />
      <Background currentPage={currentPage}></Background>
    </>
  )
};

export default Layout;