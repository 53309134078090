import React from "react";
import { motion } from "framer-motion";

const List = ({ heading, headingClass, list, liClass, ulClass }) => {
  //loop through list and append to ul
  const listItems = list.map((element) => (
    <motion.li style={{}} whileHover={{ scale: 1.1, opacity: 0.6}}
    whileTap={{
      scale: 1.2,
      borderRadius: "100%"
    }} key={element} className={liClass}>{element}</motion.li>
  ));
  return (
    <div>
      <ul className={ulClass}>{listItems}</ul>
    </div>
  );
};

export default List;
