import React from 'react'
import { Link } from 'react-router-dom'
import NavigationBar from './NavigationBar'
import Logo from './Logo'
import LetsTalk from './LetsTalk'
const Header = ({logo,visibility,functions,pageList}) => {
  
  const showHeaderBlock = true;
  //console("Should we show the header? "+ showHeaderBlock)
  return (
    <div id='region-header' className='region-header'>
        <nav className='row navbar'>
            <div style={{width : "auto !important"}}>
                <Link to='/' >
                <Logo logo={logo} location="header" functions={functions}/>
                </Link>
            </div>
            <div style={{display: showHeaderBlock ? 'block' : 'none', marginRight: '10px',
            position: 'fixed',right: '0',top: '20px'}} 
             className='navigation-buttons'>
                <NavigationBar pageList ={pageList} location="header" />
            </div>
        </nav>
    </div>
  )
}

export default Header