import React from "react";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";

const NamesHolder = () => {
    const milliseconds = 1000;
    const [loadPage, setLoadPage] = useState(false);
    const nameHolderAnimationDuration = 2;
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setLoadPage(true);
      }, milliseconds); //  10 seconds
  
  
      return () => {
        clearTimeout(loadPage);
      };
    }, []);

    var showNamesSection = true;
    return(
        <motion.div className="namesHolder row" style={{opacity: showNamesSection ? '1' : '0' }}  animate={{ y: loadPage ? 0 : 500 }} transition={{ duration: nameHolderAnimationDuration }} initial={{ y: 500 }}  >
        <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4">
          <span className="nameYellow">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4" >
          <span className="nameWhite">XXXXXXXX XXXXXX XXXXXXXX</span>
        </motion.div>
         <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
        <motion.div className="col-md-4" >
          <span className="nameWhite">MOEMEDI WAZZZA RAKHUDU</span>
        </motion.div>
      </motion.div>
    )
};

export default NamesHolder;
