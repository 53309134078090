import React from "react";
import Loading from "./Loading";
import { useEffect, useState,useRef } from 'react';
import image_project1 from '../img/project1.png';
import image_arrow_left from '../img/arrow-left.png';
import image_arrow_right from '../img/arrow-right.png';
import classNames from 'classnames';
import { motion } from "framer-motion";
import FeaturedPortfolio from "./FeaturedProjects";
import PortfolioListItem from "./PortfolioListItem";
import PortfolioPreviewItem from "./PortfolioPreviewItem";
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";

const Portfolio = ({ visibility, functions, pageList, data, resetDrag,refreshPage }) => {
  
  const listPortfolio = [
    {
      id: 1,
      title: "Sound Box Tech Support App",
      type: "Mobile Application",
      code: 'mobile',
      caption:  "Tech support android applicatiion",
      photo: "https://mrakhudu.com/sites/default/files/2021-12/soundbox.jpg",
      startDate: "January 2015",
      endDate: "October 2015",
      technologies: ["Android", "Java", "PHP", "MySQL"],
      link: "https://admin.mrakhudu.com/projects/soundbox",
      collaborators: "",
      projectLink: 'soundbox',
      collaborators: ''
    },
    {
      id: 2,
      title: "Association For Campus Entertainment",
      type: "Website",
      code: 'web',
      caption:  "Dynamic website for the organization",
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/ace_0.jpg?itok=bgTLRQCI",
      startDate: "May 2018",
      endDate: "January 2020",
      technologies: ["HTML", "JavaScriptt", "CSS", "Drupal 8", "PHP", "MySQL"],
      link: "https://admin.mrakhudu.com/projects/ace",
      projectLink: 'ace',
      collaborators: ''
    },
    {
      id: 3,
      title: "Reborn Games",
      type: "Website",
      code: 'web',
      caption:  "Premier gaming commerce website in Botswana",
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/project2.jpg?itok=XLJjm_Vo",
      startDate: "January 2019",
      endDate: "January 2022",
      technologies: ["HTML", "JavaScriptt", "CSS", "Drupal 8", "PHP", "MySQL"],
      link: "https://mrakhudu.com/Portfolio/reborn-games",
    },
    {
      id: 4,
      title: "Table Mate!",
      type: "Mobile Application",
      code: 'mobile',
      caption:  "Real time feedback solution to improve dining experiences.",
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/tablemate.png?itok=EH79Vlso",
      startDate: "March 2016",
      endDate: "Present",
      technologies: ["Xamarin Forms", "Firebase", "REST API", "C#", "PHP", "MySQL", "HTML", "Javascript", "React", "Win UI"],
      link: "https://mrakhudu.com/Portfolio/tablemate",
      projectLink: 'tablemate',
      collaborators: ''
    },
    {
      id: 4,
      title: "Table Mate!",
      type: "Mobile Application",
      code: 'desktop',
      caption:  "Real time feedback solution to improve dining experiences.",
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/tablemate.png?itok=EH79Vlso",
      startDate: "March 2016",
      endDate: "Present",
      technologies: ["Xamarin Forms", "Firebase", "REST API", "C#", "PHP", "MySQL", "HTML", "Javascript", "React", "Win UI"],
      link: "https://admin.mrakhudu.com/projects/tablemate",
      projectLink: 'tablemate',
      collaborators: ''
    },
    {
      id: 5,
      title: "Multithreaded Application",
      type: "Console Application",
      code: 'console',
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/multithread.jpg?itok=7-PMocqE",
      startDate: "January 2019",
      endDate: "May 2019",
      technologies: ["Java"],
      link: "https://admin.mrakhudu.com/projects/multithread-app",
      projectLink: 'multithread-app',
      collaborators: ''
    },
    {
      id: 6,
      title: "Where You At!",
      type: "Mobile Application",
      code: 'mobile',
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/whereyouat.png?itok=6rbEijQ_",
      startDate: "August 2019",
      endDate: "December 2019",
      technologies: ["Android", "Java", "Firebase"],
      link: "https://admin.mrakhudu.com/projects/where-you-at",
      projectLink: 'where-you-at',
      collaborators: 'Gordon White, Khang Le'
    },
    {
      id: 7,
      title: "My Analytics!",
      type: "Dashboard",
      code: 'analytics',
      photo: "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/whereyouat.png?itok=6rbEijQ_",
      startDate: "August 2019",
      endDate: "December 2019",
      technologies: ["Android", "Java", "Firebase"],
      link: "https://admin.mrakhudu.com/projects/analytics",
      projectLink: 'analytics',
      collaborators: ''
    },
  ];

  const [loadPage, setLoadPage] = useState(false);

  
  const buttonAnimationDuration = 5;
  const imageAnimationDuration = 3;
  const arrowAnimationDuration = 1;
  const textAnimationDuration = 5;

  if (refreshPage ){
    setLoadPage(false)
    console.log("Refresh!")
    functions.setRefreshPage(false)
  }


  /*  <DivPaginator itemsPerPage={10} data={listPortfolio} />*/
  const showPortfolioSection = true//visibility.showPortfolio;

  //click event
  const [selectedSection, setSelectedSection] = useState(null);
  const [showPortfolioSelectedSection, setShowSelectedSection] = useState(false);
  const [showProjectDetails, setShowProjectDetails] = useState(false);
  const [projectSelectedTitle, setProjectSelectedTitle] = useState("");
  const [projectSelectedURL, setProjectSelectedURL] = useState("");
  const [projectSelected, setShowSelectedProject] = useState("");
  
  const onClickShowPortfolioSection = () => {
    setShowSelectedSection(!showPortfolioSelectedSection);
    scrollToTop ()
    closePopup ()
  };

  const onClickPortfolioSection = (section) => {
    onClickShowPortfolioSection(section)
    setSelectedSection(section);
    onClickPortfolioProject(1,section,true)
    closePopup ()
    scrollToTop ()
    
  };

  const onClickPortfolioBack = () => {
    setShowSelectedSection(false);
    closePopup ()
    scrollToTop ()
  };

  const onClickPortfolioProject = (id,selectedSection,load) => {
    console.log("Project selected " + id + " code: "+selectedSection);
    // Find the element with the matching id
    const filteredPortfolio = listPortfolio.filter(element => element.code === selectedSection);
    const selectedProject = load ? filteredPortfolio[0] :filteredPortfolio.find((element) => element.id === id);
  
  
    if (selectedProject) {
      const projectUrl = selectedProject.photo;
      const projectTitle = selectedProject.title;
      // Use projectUrl as needed
      console.log("Selected project URL: " + projectUrl);
      // Other actions with projectUrl...
      setProjectSelectedURL(projectUrl)
      setProjectSelectedTitle(projectTitle)
    } else {
      console.log("Project not found with ID: " + id);
    }
  
    closePopup();
  };
  
  const onClickPortfolioProjectDetails = (project) => {
    console.log("OPening Projecet selected " + project.id)
    setShowProjectDetails(true)
    setShowSelectedProject(project);
  };
  function closePopup (){
    setShowProjectDetails(false)
  }
  function changeProject(isNext){
      
  }
  
  
  const divRef = useRef(null);
  const scrollToTop = () => {
    // Accessing the current property of the ref to get the DOM node
    const divElement = divRef.current;
    // Scroll the div to the top
    if (divElement) {
      divElement.scrollTop = 0;
    }
  };
  const [projectCategories, setProjectCategories] = useState(false);
  const renderProjectCategories =  () => {
      const listProjectCategoryItems = Object.keys(projectCategoryTitles).map((aCategory,index) => (
        <motion.div key={index} className={"button-portfolio"} 
                     whileHover={{ scale: 1.1, opacity: 0.6}}
                     whileTap={{
                       scale: 1.2,
                       borderRadius: "100%"
                     }}
                    style={{color: 'white'}}>
                     <Link to={'/portfolio/'+aCategory} state={{ category:aCategory }}><span>- {projectCategoryTitles[aCategory]}</span></Link>
                  </motion.div >
        ));
        setProjectCategories(listProjectCategoryItems)
      return (
        listProjectCategoryItems
      );
    return null;
  };
  //download project categories
  var projectCategoryTitles = {}
  useEffect(() => {
    fetch('https://app.mrakhudu.com/endpoints/data/fetchProjectCategories.php')
      .then(response => response.json())
      .then(content_data =>{ setData(content_data)
        console.log("Results: "+JSON.stringify(content_data))
        try{
          content_data.forEach(element => {
            
            if (element.is_visible=="1")
              projectCategoryTitles[element.category_code] = element.title
            });  
          }catch (ex){
            console.log("Error: "+ex)
          } 
          console.log(JSON.stringify(projectCategoryTitles))
          renderProjectCategories(projectCategoryTitles)
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  //download features projects
  var featuresProjects = []
  const [content_data, setData] = useState(null);
  const [featuredProjectsData, setFeaturesProjectsData] = useState([]);
  useEffect(() => {
    fetch('https://app.mrakhudu.com/endpoints/data/fetchFeaturedProjects.php')
      .then(response => response.json())
      .then(content_data =>{ setData(content_data)
        console.log("Results: "+JSON.stringify(content_data))
        try{
          content_data.forEach(project => {            
            project['photo'] = "https://admin.mrakhudu.com/sites/default/files/"+project.imageURL.replace("public://","")
            project['type'] = project.category
            project['caption'] = stripHtml(project.summary)
            project['collaborators'] = project.collaborators
            project['projectLink'] =  project.projectLink
            project['code'] =  project.category_code
            featuredProjectsData.push(project) 
            });  
          }catch (ex){
            console.log("Error: "+ex)
          } 
          console.log(JSON.stringify(featuredProjectsData))
          setFeaturesProjectsData(featuredProjectsData)
          setLoadPage(true)
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}
  return (
    <div >
      <div id="content" style={{ display: showPortfolioSection ? 'block' : 'none' }}>
        <div className="pageTitle" id="porfolioPageTitle">
          <h1>PORTFOLIO</h1>
          <h4>COOL STUFF I'VE WORKED ON</h4>
        </div>
        <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
        <br></br>
        <div className="row portolio-container" ref={divRef}>
            <div className="col-md-6">
             <FeaturedPortfolio data={featuredProjectsData} visible={showPortfolioSelectedSection ? false : true} click={onClickPortfolioProjectDetails}></FeaturedPortfolio>
           
             </div>
             
             <div className="col-md-6 portfolio-sections-container" style={{opacity: loadPage ? 1: 0}}>
                  <div id="portfolio-main-menu" style={{display: showPortfolioSelectedSection ? 'none' : 'block'}}>
                  <h4 className="portofilo-sections-title">Explore some of my notable projects below. Choose a category.</h4>
                  {projectCategories}
                  </div>
             </div>
             
        </div>
      </div>
                     
      
      <div className="project-popup" style={{backgroundColor: 'white', color: 'black', 
      width: '90vw', height:'90vh', left: '50%', right: '50%', transform: 'translate(-50%, 5%)',
      zIndex: 4000, position:'fixed', display: showProjectDetails? 'block' : 'none'}}>
                     <h4>{projectSelected.title == null ? "" :projectSelected.title}</h4>
                     <h5>{projectSelected.type== null ? "" :projectSelected.type}</h5>
                     <div onClick={closePopup}>Close</div>
                     <Iframe width='100%' height ='100%' url={projectSelected.link}/>
      </div>
    </div>
  );
};

export default Portfolio;
