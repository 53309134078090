import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
const ServiceBox = ({ id,imageSrc, labelText, descriptionText,functions, loadPage }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isTapped, setIsTapped] = useState(false);
  const imageAnimationDuration = 0.3; // Change this according to your preference
    // Check if the device is a mobile screen
   
    // Add state to determine if it's a touch device
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    // Detect if it's a touch device
    useEffect(() => {
    const onTouchStart = () => setIsTouchDevice(true);
    const onHoverStart = () => setIsTouchDevice(false);

    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('mouseenter', onHoverStart);

    return () => {
        document.removeEventListener('touchstart', onTouchStart);
        document.removeEventListener('mouseenter', onHoverStart);
    };
    }, []);


  return (
   
    <motion.div
      className="servicesBox"
      animate={{ y: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }}
      
      transition={{ duration: imageAnimationDuration }}
      initial={{ y: 0, opacity: 0 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onTapStart ={() => setIsTapped(true)}
      onTapEnd ={() => setIsTapped(false)}
    >
      <motion.div
    whileHover={!isTouchDevice ? { scale: isHovered ? 0.5 : 1 } : {}}
    className="serviceBoxContent"
    onClick={() => functions.navigateContact(id, labelText)}
>
    {(isHovered && !isTouchDevice) && (
       <motion.div
       className="service_image_overlay"
       style={{
           position: "absolute",
           top: 0,
           left: 0,
           right: 0,
           bottom: 0,
           margin: "auto",
           cursor: "pointer",
       }}
       initial={{ opacity: 0 }}
       animate={{ opacity: 0.8 }}
   >
       <svg
           className="dottedCircle"
           width="100%"
           height="140%"
           style={{ position: "relative", top: -40, margin: "auto", zIndex: 0 }}
           viewBox={`-50 -105 ${document.querySelector('.serviceBoxImage').clientWidth * 1.65 + 100 }
            ${document.querySelector('.serviceBoxImage').clientHeight * 2 + 150} `}
       >
           <circle
               cx={document.querySelector('.serviceBoxImage').clientWidth * 1.65 / 2}
               cy={document.querySelector('.serviceBoxImage').clientHeight * 1.65 / 2}
               r={document.querySelector('.serviceBoxImage').clientHeight * (1.65 / 2) + 100} // Adjusted to create a space of 50 units between the circle and image
               stroke="white"
               strokeWidth="15"
               fill="none"
               strokeDasharray="10,15"
           />
       </svg>
   </motion.div>
   
   
    )}
    <motion.img className="serviceBoxImage" src={imageSrc} />
    
    
</motion.div>

     
      {(isHovered || isTouchDevice ) && (
        <motion.div
          className="descriptionDiv"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: -10 }}
          transition={{ duration: 0.3 }}
          style={{cursor: "pointer"}}
        >
          <p>{descriptionText}</p>
        </motion.div>
      )}
        <h4 className="servicesLabel" style={{marginTop: isHovered && !isTouchDevice ? '-20px' : '0px',
        color: isHovered && !isTouchDevice ? 'white' : '#6CFF6C'  }} >{labelText}</h4>  
        {isTouchDevice && (
            <div>         
            <hr style={{color: 'white', border: '0', borderTop: '3px solid',
            opacity: '.55', maxWidth: '200px', margin: 'auto'}}></hr>
            <br></br>
            </div>
    )}
    </motion.div>
   
  );
};

export default ServiceBox;
