import React from "react";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const NavigationList = ({ heading, headingClass, list, liClass, ulClass,toggleMenu }) => {
  //loop through list and append to ul

  function navigate(pageFunction){
    //close the navigation bar before navigating to new page
    toggleMenu()
    //navigae to new page
    pageFunction()
  }

  const listItems = list.map((element) => (
    <motion.li 
    whileHover={{ scale: 1.1, opacity: 0.6}}
    whileTap={{
      scale: 1.2,
      borderRadius: "100%"
    }}key={element.id} className={liClass}>
       <Link to={element.uri}>
      <span className={ulClass} onClick={() =>navigate(element.link)}>{element.title}</span>
      </Link>
      {/* <Link to="/skills">Skills</Link> */}
      
      </motion.li>
  ));
  return (
    <div>
      {heading &&<p className={headingClass}>{heading}</p>}
      <ul className={ulClass}>{listItems}</ul>
    </div>
  );
};

export default NavigationList;
