import React from 'react'
import { useEffect, useState } from 'react';
const Background = ({currentPage}) => {
    const [imageUrl, setImageUrl] = useState(currentPage.toString());
    console.log("curr page "+currentPage.replace("/","").replace("/","-"))
    useEffect(() => {
      // Fetch your image URL from an API or other source
      // For this example, we'll just use a static URL
      console.log("updating image to "+currentPage);
      setImageUrl("/background-"+currentPage.replace("/","").replace("/","-")+".png");
    }, []);
    var pageURL =  "";
   try {
     pageURL =  require("../img/background-"+currentPage.replace("/","").replace("/","-")+".png");
   } catch (error) {
     pageURL =  "";
   }

   const [loading, setLoading] = useState(false);

   const handleImageChange = () => {
     setLoading(true);
     const newImageUrl = "https://example.com/background-image-2.jpg";
     const img = new Image();
     img.src = newImageUrl;
     img.onload = () => {
    //   setBackgroundImageUrl(newImageUrl);
       setLoading(false);
     };
   };


   const styles = {
      backgroundImage: `url(${pageURL})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      minHeight: "100vh",
      transition: "background-image 1s ease-in-out",
    };
  return (
    <div style={styles}></div>
  )
}

export default Background