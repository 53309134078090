import React from "react";
import List from "./List";
import Loading from "./Loading";
import ServiceBox from './ServiceBox'; 
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import image_service_mobile from '../img/mobile-development.png';
import image_service_desktop from '../img/computer.png';
import image_service_website from '../img/internet.png';
import image_service_api from '../img/api.png';
import image_service_automation from '../img/automation.png';
import image_service_dashboards from '../img/data-analysis.png';

import { Outlet, Link } from "react-router-dom";
const Services = ({ visibility, functions, pageList, data, resetDrag,refreshPage }) => {
  const imageAnimationDuration = 2
  const [loadPage, setLoadPage] = useState(false);
  const [servicesBox, setServicesBox] = useState(null);
  const showServicesSection = true//visibility.showServices;
  const [isHovered, setIsHovered] = useState(false);
  var servicesList =[]
  //Download DATA
  const [content_data, setData] = useState(null);
  useEffect(() => {
    fetch('https://app.mrakhudu.com/endpoints/data/fetchServices.php')
      .then(response => response.json())
      .then(content_data =>{ setData(content_data)
        console.log("Results: "+JSON.stringify(content_data))
          servicesList = content_data
          console.log(JSON.stringify(servicesList))
          ServiceBoxes()
          setLoadPage(true)
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  //parse data
  function ServiceBoxes(){
    console.log("Rendering "+ servicesList)
    var servicesBoxElement = servicesList.map((service, index) => (
      
      <div key={index} className="col-md-4">
      <Link to={"/contact?service="+service.uri} element={""
         }
           ><ServiceBox
           imageSrc={"https://admin.mrakhudu.com/sites/default/files/"+service.imageSrc.replace("public://","")}
           labelText={service.labelText}
           descriptionText={stripHtml(service.descriptionText)}
           functions={functions}
           loadPage={true}/>
     </Link>
     
   </div>
      ))
      
      setServicesBox(servicesBoxElement)
    return (
      servicesBoxElement
    );
  }
  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}
  return (
    <div id="services" className="region region-services">
      <div id="content" style={{ display: showServicesSection ? 'block' : 'none' }}>
        <div className="pageTitle" id="servicesPageTitle">
          <h1>SERVICES</h1>
          <h4>WHAT I WILL BE HELPING YOU WITH</h4>
        </div>
        <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
        <motion.div id="servicesContainer" className="row"   >
              {servicesBox}
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
