import React from "react";
import image_more_info from '../img/more_info.png';
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
const PortfolioPreviewItem = ({key,url, title, headingClass, list, liClass, ulClass ,onClick  }) => {
  function onClickPortfolioProject(key){
    onClick(key)
  }
  // Add state to determine if it's a touch device
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isTapped, setIsTapped] = useState(false);
  
  // Detect if it's a touch device
  useEffect(() => {
  const onTouchStart = () => setIsTouchDevice(true);
  const onHoverStart = () => setIsTouchDevice(false);

  document.addEventListener('touchstart', onTouchStart);
  document.addEventListener('mouseenter', onHoverStart);

  return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('mouseenter', onHoverStart); 
  };
  }, []);
  return (
    <div className="container-project-preview">
      <div className="container-button-project-info">
        <h3 className="selected-project-title" 
        style={{textAlign: "left", position: "relative", display: "inline"}}>
          {title}
          </h3>
       <motion.img className="button-project-info" 
          whileHover={{ scale: isHovered && !isTouchDevice ? 1.1 : 0, opacity: !isTouchDevice ? 0.6 : 1}}
          whileTap={{
            scale: !isTouchDevice ? 1.2 : 1,
            borderRadius: "100%"
          }}
          animate={{
            scale: [1, 1.1, 1, 1.1,1], // Array of scales, will animate between these values
            transition: {
              duration: 2, // Duration of one cycle of the animation
              ease: "easeInOut", // Easing function for the animation
              repeat: Infinity
            }
          }}
        onClick={() =>onClickPortfolioProject(key)} 
       height='40' width='40' src={image_more_info} style={{position: "relative", display: "inline"}}/>
       </div>
      <motion.div className={"button-portfolio"} 
                     whileHover={{ scale: isHovered && !isTouchDevice ? 1.1 : 1, opacity: !isTouchDevice ? 0.6 : 1}}
                     whileTap={{
                       scale: !isTouchDevice ? 1.2 : 1,
                       borderRadius: "100%"
                     }}
                     onClick={() =>onClickPortfolioProject(key)} 
                     onHoverStart={() => setIsHovered(true)}
                      onHoverEnd={() => setIsHovered(false)}
                      onTapStart ={() => setIsTapped(true)}
                      onTapEnd ={() => setIsTapped(false)}
                     >
                     <motion.img src={url} className="portfolio-project-preview" 
                     animate={{
                      scale: [1, 1.01, 1, 1.01,1], // Array of scales, will animate between these values
                      transition: {
                        duration: 6, // Duration of one cycle of the animation
                        ease: "easeInOut", // Easing function for the animation
                        repeat: Infinity
                      }
                    }}
                    style={{
                      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.4)" // Transparent grey box shadow
                    }}
                     ></motion.img>

                  </motion.div >
                 
            <motion.div onClick={() =>onClickPortfolioProject(key)} 
             whileHover={{ scale: isHovered && !isTouchDevice ? 1.1 : 0, opacity: !isTouchDevice ? 0.6 : 1}}
             whileTap={{
               scale: !isTouchDevice ? 1.2 : 1,
               borderRadius: "100%"
             }}
            style={{position: 'absolute', zIndex:'100',top:0,right:20}}
            ></motion.div>
    </div>
  );
};

export default PortfolioPreviewItem;
