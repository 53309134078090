import React from "react";
import { useEffect, useState } from 'react';
import Slider from "./Slider";
import classNames from 'classnames';
import { motion } from "framer-motion";

const Loading = ({visibility,functions}) => {
  const listHomePageSlides = [
    {
      id: 1,
      title: "Hi! I'm Moemedi. I design and build websites and applications",
      caption: "",
      image:
        "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/carousel1_0.jpg?itok=owiO1TFy",
      backgroundImage: "",
    },
    {
      id: 2,
      title: "Hi! I'm Moemedi. I design and build websites and applications",
      caption: "",
      image:
        "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/carousel2.jpg?itok=DigH79_8",
      backgroundImage: "",
    },
    {
      id: 3,
      title: "Hi! I'm Moemedi. I design and build websites and applications",
      caption: "",
      image:
        "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/carousel3.jpg?itok=at00Zut9",
      backgroundImage: "",
    },
    {
      id: 4,
      title: "Hi! I'm Moemedi. I design and build websites and applications",
      caption: "",
      image:
        "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/carousel4.jpg?itok=LNO51F-5",
      backgroundImage: "",
    },
    {
      id: 5,
      title: "Hi! I'm Moemedi. I design and build websites and applications",
      caption: "",
      image:
        "https://mrakhudu.com/sites/default/files/styles/large/public/2021-12/carousel5.jpg?itok=WQnrZqGl",
      backgroundImage: "",
    },
  ];
  const showIndicators = false;
  const showNavigation = false;
  const interval = 10000;
  const slideLimit = 5;
  const fullwidth = true;
  const fade = true;
  const slide = false;
  const [playAnimation, setPlayAnimation] = useState(false);
  const loadingClass = "loading-post";
  // This will run one time after the component mounts
  const [rotate, setRotate] = React.useState(false);
  const [hideDiv, setHideDiv] = useState(false);
  const [loadDone, setLoadDone] = useState(false);
  const milliseconds = 1000;
  const letterAnimationRepeat = 3;
  const letterAnimationDuration = 2;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHideDiv(true);
    }, milliseconds * 10); //  10 seconds
    const timerLoadDone = setTimeout(() => {
      setLoadDone(true);
      showHeader();
    }, milliseconds * 12); // 15000ms = 15 seconds
    //loadDone

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timerLoadDone);
    };
  }, []);

  
  function showHeader(){
    console.log("showing header!!");
    functions.showHideHeader(true);
  }

  return (
    <div>
      <div id="loading" className="loading">
        {!hideDiv && <div id="loading-name" >
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: -265, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ repeat: letterAnimationRepeat, duration: letterAnimationDuration }} exit={{ opacity: 0 }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: -225, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.1, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: -185, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.2, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>

          <motion.span className="loading-letter" animate={{ y: [0, 40, 0,] }}
            initial={{ x: -145, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.3, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>

          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: -105, y: 0.2 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.4, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>

          <motion.span className="loading-letter" animate={{ y: [0, 40, 0,] }}
            initial={{ x: -65, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.5, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: -25, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.6, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>


          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 25, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.7, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 65, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.8, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 105, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 0.9, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 145, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 1, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 185, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 1.1, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 225, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 1.2, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.span className="loading-letter" animate={{ y: [0, 40, 0] }}
            initial={{ x: 265, y: 0 }} whileHover={{ scale: 3 }}
            transition={{ delay: 1.3, repeat: letterAnimationRepeat, duration: letterAnimationDuration }}
          >X</motion.span>
          <motion.div style={{ display: "none" }}
            animate={{ scale: 1, rotate: rotate ? 360 : 0 }} whileHover={{ scale: 3 }}
            transition={{ type: "tween", bounce: 5, duration: 5, repeat: 10 }}
            initial={{ scale: 0 }} drag dragConstraints={{ left: 50 }}
            onClick={() => { setRotate(!rotate) }}
            className="loading-pre"><span>XXXXXXX XXXXXXX</span></motion.div>
        </div>}
        <motion.div initial={{ scale: 0, opacity: 0 }}
          transition={{ delay: loadDone ? 0 : 10, }}
          animate={{ scale: loadDone ? 3 : 1, duration: 2, opacity: loadDone ? 0 : 1 }}
          className={classNames('myComponentClass', { myAnimation: playAnimation })}>
          <span>MOEMEDI RAKHUDU</span></motion.div>
      </div>
   
    </div>
  );
};

export default Loading;
