import React from "react";
import Slider from "./Slider";

const FeaturedPortfolio = ({data,visible,click}) => {
  
  const showIndicators = true;
  const showNavigation = false;
  const interval = 5000;
  const slideLimit = 5;
  const layout = "one-col"
  const fade = false;
  const slide = true
  return (
    <div id="start" style={{display: visible ? 'block' : 'none'}} className="region region-featured-Portfolio">
      <Slider
        slides={data}
        showIndicators={showIndicators}
        showNavigation={showNavigation}
        interval={interval}
        slideLimit={slideLimit}
        layout={layout}
        fade={fade}
        slide ={slide}
        click = {click}
      />
    </div>
  );
};

export default FeaturedPortfolio;
