import React from "react";
import List from "./List";
import DraggableWindow from "./DraggableWindow";
import Loading from "./Loading";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import { Link, useParams } from 'react-router-dom';
const Skills = ({ visibility, functions, pageList, data, resetDrag }) => {
   // Use useParams to get the section parameter from the URL
   let { section } = useParams();
  const liClass = "skills-list";
  const headingClass = "skills-headings"
  const [loadPage, setLoadPage] = useState(false);
  const [skillBox, setSkillsBox] = useState(null);
  const resumeLink ="https://admin.mrakhudu.com/sites/default/files/Resume_Business_Analyst.pdf"
  const imageAnimationDuration = 2;
 
  const showSkillsSection = true// visibility.showSkills;
  var skillDict = {}
  //Download DATA
  const [content_data, setData] = useState(null);
    useEffect(() => {
      fetch('https://app.mrakhudu.com/endpoints/data/fetchSkills.php')
        .then(response => response.json())
        .then(content_data =>{ setData(content_data)
          console.log("Results: "+JSON.stringify(content_data))
          
          
          try{
            content_data.forEach(element => {
                  if (element.vid == "skills"){
                    element["subcategories"] = {}
                    skillDict[element.tid] = element
                    console.log("MAIN: "+element.title)
                  }
                  if (element.vid == "skills_subcategory"){
                    console.log("SUB: "+element.title + " cat: "+element.category)
                    element["skills"] = []
                    element["parent_id"] = element.category
                    skillDict[element.category]["subcategories"][element.tid]=element
                  }
                  if (element.vid == "skills_items"){
                    console.log("ITEM: "+element.title)
                    skillDict[element.category]["subcategories"][element.subcategory]["skills"].push(element)

                  }
              });  
            }catch (ex){
              console.log("Error: "+ex)
            } 
            console.log(JSON.stringify(skillDict))
            renderSkillsBox()
            setLoadPage(true)
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);
    //parse data
    function renderSkillsBox(){
      console.log("Rendering "+ Object.keys(skillDict))
      var skillBoxElement = Object.keys(skillDict).filter((_, index) => [0].includes(index)).map((item, index) => (
          <motion.div className="skillsBox" animate={{ y: 0 , opacity: 1 }}
                        transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity: 0 }} >                         
                            <div id="layout-skills-1" >                           
                              <div id="layout-skills-col-1">
                                {/** MAIN **/}
                                <h4 className="skills-section-name">{skillDict[item]["title"]}</h4>
                                <div className="row">
                                    <div className="col-md-6 skills-col-1-col-1">
                                    {Object.values(skillDict[item]["subcategories"]).filter((_sub, index_sub) => [0,1].includes(index_sub)).map(skill => (
                                      <div>
                                        <h5>{skill.title}</h5>
                                        <List 
                                          heading={skill.title} 
                                          headingClass={headingClass} 
                                          list={skill.skills.map(skillItem => skillItem.title)} 
                                          ulClass="" 
                                          liClass={liClass} 
                                        />
                                      </div>
                                    ))}
                                    </div>
                                    <div className="col-md-6 skills-col-1-col-2">
                                    {Object.values(skillDict[item]["subcategories"]).filter((_sub, index_sub) => [2].includes(index_sub)).map(skill => (
                                      <div>
                                        <h5>{skill.title}</h5>
                                        <List 
                                          heading={skill.title} 
                                          headingClass={headingClass} 
                                          list={skill.skills.map(skillItem => skillItem.title)} 
                                          ulClass="" 
                                          liClass={liClass} 
                                        />
                                      </div>
                                    ))}
                                    </div>
                                  </div>
                              </div>
                              
                            </div>
          </motion.div>
        ))
        var skillBoxElement2 = Object.keys(skillDict).filter((_, index) => [1,2].includes(index)).map((item, index) => (
        
          <motion.div className="skillsBox" animate={{ y: 0 , opacity: 1 }}
                        transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity: 0 }} >                         
                            <div id="layout-skills-2" >                           
                              <div id="layout-skills-col-2" >
                                {/** MAIN **/}
                                <h4 className="skills-section-name">{skillDict[item]["title"]}</h4>
                                  {Object.values(skillDict[item]["subcategories"]).map(skill => (
                                    <div>
                                      <h5>{skill.title}</h5>
                                      <List 
                                        heading={skill.title} 
                                        headingClass={headingClass} 
                                        list={skill.skills.map(skillItem => skillItem.title)} 
                                        ulClass="" 
                                        liClass={liClass} 
                                      />
                                    </div>
                                  ))}
                              </div>
                              
                            </div>
          </motion.div>
        ))
        setSkillsBox(<div className="row"><div className="col-md-8">{skillBoxElement}</div><div className="col-md-4">{skillBoxElement2}</div></div>)
      return (
        skillBoxElement
      );
    }

  return (
    <div id="skills" className="region region-skills">
      <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
      <div id="content" style={{ display: showSkillsSection ? 'block' : 'none' }}>
        <div className="pageTitle" id="skillsPageTitle">
          <h1>SKILLS</h1>
          <h4 >WHAT I AM GOOD AT</h4>
        </div>
        <br></br>
        <div id='skillsContainer' className="row">
        <DraggableWindow title={"Resume.pdf"} image={""} content={"Download My Resume"} type="resume" resumeLink = {resumeLink}
              loadPage={loadPage} resetDragVar={true} imageAnimationDuration = {imageAnimationDuration}/>
          {skillBox}
          </div>
      </div>
    </div>
  );
};

export default Skills;
