import logo from './img/logo-smaller.png';
import bioPhoto from './img/me.jpeg';
import './App.css';
import Header from './components/Header';
import Education from './components/Education';
import Contact from './components/Contact';
import Services from './components/Services';
import About from './components/About';
import Portfolio from './components/Portfolio';
import PortfolioProjectCategory from './components/PortfolioProjectCategory';
import Project from './components/Project';

import Skills from './components/Skills';
import Introduction from './components/Introduction';
import Loading from './components/Loading';
import Home from './components/Home';
import { useState } from 'react';
import Background from './components/Background';


import Layout from "./components/pages/Layout";
import Home2 from "./components/pages/Home";
import Blogs from "./components/pages/Blogs";
import Contact2 from "./components/pages/Contact";
import NoPage from "./components/pages/NoPage";

import {
  BrowserRouter as Router,
  Route,Routes,
  Link
} from "react-router-dom";
function App() {
  const year =new Date().getFullYear();
  const copyright = "Copyright 2020 - "+year+" © - All Rights Reserved"
  const pageList =[{id:1,title: "HOME",uri:'/'},{id:2,title: "ABOUT",uri:'/about'},{id:3,title: "SERVICES",uri:'/services'},
  {id:4,title: "SKILLS",uri:'/skills'},{id:5,title: "PORTFOLIO",uri:'/portfolio'},{id:6,title: "LET'S TALK",uri:'/contact'}]

  const [serviceSelected,setServiceSelected] = useState([]);
  const [refreshPage,setRefreshPage] = useState(false);


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout logo={logo}  
      pageList={pageList} />}>
          <Route index  element={  <Introduction pageList={pageList} refreshPage={refreshPage}/> }>   
          </Route>
          <Route path="menu"  element={  <Home  pageList={pageList} refreshPage={refreshPage}/> }>   
          </Route>
          <Route path="about" element={ <About  pageList={pageList}  refreshPage={refreshPage}/>} />
          <Route path="services" element={  <Services  pageList={pageList}  refreshPage={refreshPage}/>
        } />
          <Route path="skills" element={  <Skills  pageList={pageList}  refreshPage={refreshPage}/>
       } />
          <Route path="portfolio" element={<Portfolio  pageList={pageList}  refreshPage={refreshPage}/>} />

          {/* Route for 'portfolio/something' */}
          <Route path="portfolio/:category" element={<PortfolioProjectCategory  pageList={pageList}  refreshPage={refreshPage}/>} />

          {/* Route for 'portfolio/something/another' */}
          <Route path="portfolio/:category/:project" element={<Project  pageList={pageList} refreshPage={refreshPage} projectSelected = {""}/>} />

          <Route path="projects" element={<Portfolio pageList={pageList}  refreshPage={refreshPage}/>} />

        {/* Route for 'portfolio/something' */}
        <Route path="projects/:category" element={<PortfolioProjectCategory  pageList={pageList} refreshPage={refreshPage}/>} />

        {/* Route for 'portfolio/something/another' */}
        <Route path="projects/:category/:project" element={<Project  pageList={pageList}  refreshPage={refreshPage} projectSelected = {""}/>} />

        <Route path="education" element={   <Education pageList={pageList}  serviceSelected= {serviceSelected} refreshPage={refreshPage}/>
     } />


          <Route path="contact" element={   <Contact  pageList={pageList} serviceSelected= {serviceSelected} refreshPage={refreshPage}/>
     } />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
