import React from "react";
import image_gabz from '../img/gabz.png';
import image_arrow_home from '../img/arrow-home.png';
import NamesHolder from "./NamesHolder";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Route,Routes,
  Link
} from "react-router-dom";
const Introduction = () => {
    const [loadPage, setLoadPage] = useState(true);
    const buttonAnimationDuration = 2;
    const imageAnimationDuration = 1;
    const arrowAnimationDuration = 1;
    const textAnimationDuration = 2;
    var showIntroSection = true;
  return (
    <div >
      <NamesHolder visibility={showIntroSection}></NamesHolder>
      <div id="content" style={{display: showIntroSection ? 'block' : 'none' }}>
        <div className="row">
          <div className="col-md-4 welcomeText d-flex align-items-center justify-content-end">
            <motion.div style={{marginTop: '20vh'}}
              animate={{ x: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }}
              transition={{ duration: textAnimationDuration }}
              initial={{ x: -500, opacity: 0 }}
            >
              <div>HI THERE,</div>
              <div>LET'S PLAY</div>
            </motion.div>
          </div>
            <div className="col-md-6">
              <motion.img className="homePhoto" src={image_gabz} animate={{ y: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }} 
              transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity:0 }} ></motion.img>
             <Link to='/menu'>
              <motion.button className="button-yellow"  animate={{ x: loadPage ? 0 : 3000, opacity: loadPage ? 1 : 0}} 
              transition={{ duration: buttonAnimationDuration }} initial={{ x: 3000 }} 
               whileHover={{ scale: 1.1, opacity: 0.6}}
               whileTap={{
                scale: 1.2,
                borderRadius: "100%"
              }}>PRESS START</motion.button>
              </Link>
            </div>
            <div className="introduction-arrow">
               <motion.img className="image-introduction-arrow" src={image_arrow_home} animate={{ x: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0, rotate: loadPage ? 0 :180 }}
                transition={{ loop: Infinity, delay: 1,duration: arrowAnimationDuration }} initial={{ x: 700, opacity:0,rotate:785 }} ></motion.img>
             
            </div>
        </div>
      </div>
    </div>
  )
}

export default Introduction