import React from "react";

import { motion } from "framer-motion";
const PortfolioListItem = ({key, title, headingClass, list, liClass, ulClass,onClick  }) => {
  function onClickPortfolioProject(key){
    onClick(key)
  }
  
  return (
    <li key={key} >
           <motion.div className={"button-portfolio"} 
                     whileHover={{ scale: 1.1, opacity: 0.6}}
                     whileTap={{
                       scale: 1.2,
                       borderRadius: "100%"
                     }}
                     onClick={() =>onClickPortfolioProject(key)} 
                    style={{color: 'white'}}>
                     <span>- {title}</span>
                  </motion.div ></li>
  );
};

export default PortfolioListItem;
