import React from "react";

const Logo = ({logo,location,functions}) => {
  return (
    <div className={ location == 'header' ? "navbar-brand logo-header " :  "navbar-brand logo-footer"} href="/">
      <img src={logo} onClick={functions.navigateHome} />
    </div>
  );
};

export default Logo;
