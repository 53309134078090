import React from "react";
import List from "./List";
import DraggableWindow from "./DraggableWindow";
import Loading from "./Loading";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import { Link, useParams } from 'react-router-dom';
const Education = ({ visibility, functions, pageList, data, resetDrag }) => {
   // Use useParams to get the section parameter from the URL
   let { section } = useParams();
  const title = "Education"
  const liClass = "skills-list";
  const headingClass = "skills-headings"
  const [loadPage, setLoadPage] = useState(false);
  const [eduBox, setSkillsBox] = useState(null);
  const [content, setContent] = useState("");
  const resumeLink ="https://admin.mrakhudu.com/sites/default/files/Resume_Business_Analyst.pdf"
  const imageAnimationDuration = 3;
 
  const showSkillsSection = true// visibility.showSkills;
  var skillDict = {}
  //Download DATA
  const [content_data, setData] = useState(null);
    useEffect(() => {
      fetch('https://app.mrakhudu.com/endpoints/data/fetchEducation.php')
        .then(response => response.json())
        .then(content_data =>{ setData(content_data)
          console.log("Results: "+JSON.stringify(content_data))
          var content_raw = "";
          try{
            content_raw = "<div>" +content_data[0].education +"</div>" +  "<div>" +content_data[0].education2 +"</div>"
            }catch (ex){
              console.log("Error: "+ex)
            }

            setContent (content_raw)
            console.log(JSON.stringify(skillDict))
            renderSkillsBox(content_data)
            setLoadPage(true)
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);

 //parse data



    //parse data
    function renderSkillsBox(content_data){
      console.log("Rendering "+ Object.keys(content_data[0]))
      var eduBoxElement = 
          <motion.div className="skillsBox" animate={{ y: 0 , opacity: 1 }}
                        transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity: 0 }} >                         
                            <div id="layout-skills-1" >                           
                              <div id="layout-skills-col-1">
                                {/** MAIN **/}
                                <h4 className="skills-section-name">Tertiary</h4>
                                <div dangerouslySetInnerHTML={{ __html: content_data[0].education.replaceAll("button-white","button-ed-white") }} ></div>
                                  </div>
                              
                            </div>
          </motion.div>
      
        var eduBoxElement2 = 
        
          <motion.div className="skillsBox" animate={{ y: 0 , opacity: 1 }}
                        transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity: 0 }} >                         
                            <div id="layout-skills-2" >                           
                              <div id="layout-skills-col-2" >
                                {/** MAIN **/}
                                <h4 className="skills-section-name">High School</h4>
                                <div dangerouslySetInnerHTML={{ __html: content_data[0].education2.replaceAll("button-white","button-ed-white") }} ></div>
                              </div>
                              
                            </div>
          </motion.div>
       
        setSkillsBox(<div className="row"><div className="col-md-6">{eduBoxElement}</div><div className="col-md-6">{eduBoxElement2}</div></div>)
      return (
        eduBoxElement
      );
    }

  return (
    <div id="skills" className="region region-skills">
      <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
      <div id="content" style={{ display: showSkillsSection ? 'block' : 'none' }}>
        <div className="pageTitle" id="skillsPageTitle">
          <h1>EDUCATION</h1>
          <h4 >WHAT I STUDIED</h4>
        </div>
        <br></br>
        <div id='skillsContainer' className="row">
        <DraggableWindow title={"Resume.pdf"} image={""} content={"Download My Resume"} type="resume" resumeLink = {resumeLink}
              loadPage={loadPage} resetDragVar={true} imageAnimationDuration = {imageAnimationDuration}/>
       {/* <DraggableWindow title={title} image={""} content={content} type = "education" resumeLink={""}
              loadPage={loadPage} resetDragVar={true} imageAnimationDuration = {imageAnimationDuration}/>*/}
            
          {eduBox}
          </div>
      </div>
    </div>
  );
};

export default Education;
