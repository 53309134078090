import React from "react";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
const HomeNavigationList = ({ functions,visibilty,pageList }) => {
  //remove home page
  const navigationPages =pageList.filter((fruit, index) => index !== 0);;
  //loop through list and append to ul
  const listItems = navigationPages.map((element) => (
    <motion.li
    whileHover={{ scale: 1.1, opacity: 0.6}}
                     whileTap={{
                       scale: 1.2,
                       borderRadius: "100%"
                     }}
    key={element.id} className="home-nav-item">
       <Link to={element.uri}>
      <span class="play-icon">&#x25B6;</span> <span>{element.title}</span>
      </Link>
      </motion.li>
  ));
  return (
    <div>
      <ul className="home-nav-list">{listItems}</ul>
    </div>
  );
};

export default HomeNavigationList;
