import React from "react";
import SocialMedia from "./LetsTalk";
import NavigationList from "./NavigationList"
import { useEffect, useState } from 'react';
import { Outlet, Link } from "react-router-dom";
const NavigationBar = ({location,pageList, functions}) => {
  const lestalk =pageList[5];
  const navigationPages =pageList.filter((fruit, index) => index !== 5);;
  const navigationPages2 =[lestalk]
  const ulClass =location == "header"? "main-menu navbar-nav": "footer-menu navbar-nav";
  const ulLetsTalkClass =location == "header"? "lets-talk-button": "lets-talk-button-hide ";

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
     
    <nav className={`navigation-bar ${isOpen ? 'open' : ''}`} >  
      <NavigationList ulClass={ulClass} list ={navigationPages} liClass="nav-item" toggleMenu={toggleMenu} style={{display: isOpen  ? 'block' : 'none'}} />
      <NavigationList ulClass={ulLetsTalkClass} list ={navigationPages2} liClass="nav-item" 
      toggleMenu = {toggleMenu} style={{display: isOpen  ? 'block' : 'none'}} />
       <div className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </div>
    </nav>

    </div>
  );
};

export default NavigationBar;
