import React from "react";
import Loading from "./Loading";
import { useEffect, useState,useRef} from 'react';
import image_project1 from '../img/project1.png';
import image_arrow_left from '../img/arrow-left.png';
import image_arrow_right from '../img/arrow-right.png';
import classNames from 'classnames';
import { motion } from "framer-motion";
import FeaturedPortfolio from "./FeaturedProjects";
import PortfolioListItem from "./PortfolioListItem";
import PortfolioPreviewItem from "./PortfolioPreviewItem";
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom'
const Project = ({ visibility, functions, pageList, data, resetDrag,refreshPage }) => {
  const location = useLocation();
  const [projectSelected, setProjectSelected] = useState(location.state?.projectSelected);
  const [parentPage, setParentPage] = useState(location.state?.category);
  const [loadPage, setLoadPage] = useState(false);
  const buttonAnimationDuration = 5;
  const imageAnimationDuration = 3;
  const arrowAnimationDuration = 1;
  const textAnimationDuration = 5;

    console.log(projectSelected)

    const { category, project } = useParams(); // assuming your route has :category/:project
    console.log("Page Route: " + category + " /  "+project)

    
      const defaultURL = "https://admin.mrakhudu.com/projects/"+project;
      console.log("Default URL " + defaultURL)
    const projectURL = projectSelected == null ? defaultURL : "https://admin.mrakhudu.com/projects/"+projectSelected.projectLink

  if (refreshPage ){
    setLoadPage(false)
    console.log("Refresh!")
    functions.setRefreshPage(false)
  }

  
  /*  <DivPaginator itemsPerPage={10} data={listPortfolio} />*/
  const showPortfolioSection = true//visibility.showPortfolio;

  const divRef = useRef(null);
  const scrollToTop = () => {
    // Accessing the current property of the ref to get the DOM node
    const divElement = divRef.current;
    // Scroll the div to the top
    if (divElement) {
      divElement.scrollTop = 0;
    }
  };
  return (
    <div >
      <div id="content" style={{ display:  'block' }}>
        <div className="row project-container" ref={divRef}>
        <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
            <Iframe width='100%' height ='100%' url={projectURL}/>
        </div>
      </div>
    </div>
  );
};

export default Project;
