import React from "react";
import Loading from "./Loading";
import { useEffect, useState,useRef } from 'react';
import image_project1 from '../img/project1.png';
import image_arrow_left from '../img/arrow-left.png';
import image_arrow_right from '../img/arrow-right.png';
import classNames from 'classnames';
import { motion } from "framer-motion";
import FeaturedPortfolio from "./FeaturedProjects";
import PortfolioListItem from "./PortfolioListItem";
import PortfolioPreviewItem from "./PortfolioPreviewItem";
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom'
const PortfolioProjectCategory = ({ visibility, functions, pageList, data, resetDrag,refreshPage }) => {
  const location = useLocation();
 
  const [loadPage, setLoadPage] = useState(false);

  const buttonAnimationDuration = 5;
  const imageAnimationDuration = 3;
  const arrowAnimationDuration = 1;
  const textAnimationDuration = 5;

  if (refreshPage ){
    setLoadPage(false)
    console.log("Refresh!")
    functions.setRefreshPage(false)
  }

  const showPortfolioSection = true//visibility.showPortfolio;



  const { category } = useParams(); // assuming your route has :category/:project
  console.log("Page Route: " + category )

  const projectCategory = location.state?.category == null ? category : location.state?.category


  //click event
  const [selectedSection, setSelectedSection] = useState(projectCategory);
  const [showPortfolioSelectedSection, setShowSelectedSection] = useState(false);
  const [showProjectDetails, setShowProjectDetails] = useState(false);

  const [projectSelectedURL, setProjectSelectedURL] = useState("");
  const [projectSelectedTitle, setProjectSelectedTitle] = useState("");
  const [projectSelected, setSelectedProject] = useState("");
  var [listPortfolio, setListPortfolio] = useState([]);

  const onClickShowPortfolioSection = () => {
    setShowSelectedSection(!showPortfolioSelectedSection);
    scrollToBottom ()
    closePopup ()
  };


  const onClickPortfolioBack = () => {
    setShowSelectedSection(false);
    closePopup ()
    scrollToBottom ()
  };

  const onClickPortfolioProject = (id,selectedSection,load,data_raw) => {
    console.log("Project selected " + id + " code: "+selectedSection);
    // Find the element with the matching id
    console.log(JSON.stringify(data_raw))
    const filteredPortfolio = data_raw.filter(element => element.code === selectedSection);
    const selectedProject = load ? filteredPortfolio[0] :filteredPortfolio.find((element) => element.id === id);
    setSelectedProject (selectedProject)
    if (selectedProject) {
      const projectUrl = selectedProject.photo;
      const projectTitle = selectedProject.title;
      // Use projectUrl as needed
      console.log("Selected project URL: " + projectUrl);
      console.log("Selected project Title: " + projectTitle);
      
      setProjectPreview(renderProjectsPreviews(data_raw,selectedSection,false,selectedProject))
      scrollToBottom()
    } else {
      console.log("Project not found with ID: " + id);
    }
  
    closePopup();
  };
  
  const onClickPortfolioProjectDetails = (project) => {
    console.log("OPening Projecet selected " + project.id)
    //setShowProjectDetails(true)
    //setShowSelectedProject(project);
    //navigate to project page
    window.location.href = project.code+"/"+project.projectLink;
  };
  function closePopup (){
    setShowProjectDetails(false)
  }
  function changeProject(isNext){
      
  }
  const renderProjectsList = (data_raw) => {
    console.log("Render "+data_raw)
    setListPortfolio(data_raw); 
    console.log("Render "+listPortfolio)
    try{
    const filteredPortfolio = data_raw.filter(element => element.code ===selectedSection);
      const listProjectItems = filteredPortfolio.map((element) => (
        <PortfolioListItem key={element.id} title={element.title} category={element.code} onClick={() =>onClickPortfolioProject(element.id,selectedSection,false,data_raw)}/>
      ));
      
      return (
        <div>
          <h4 className="portofilo-sections-title">{projectCategoryTitles[selectedSection]}</h4>
          <ul>
            {listProjectItems}
          </ul>
        </div>
      );
    }catch (ex){
        console.log ("Error Project List: "+ex)
    }
    return (
      <div>
        <h4 className="portofilo-sections-title">{projectCategoryTitles[selectedSection]}</h4>
        <ul>
        </ul>
      </div>
    )
  };
  const renderProjectsPreviews = (data_raw,aSelectedSection, firstRun,projectSelected) => {
    // Logic to render projects based on the selected section
    console.log("rendering preview  - "+aSelectedSection + " data "+ JSON.stringify(projectSelected))
    try{
    const filteredPortfolio = data_raw.filter(element => element.code === aSelectedSection);     
    return (
      <div >
        <Link style={{textDecoration : "none"}} to={firstRun ? filteredPortfolio[0].projectLink : projectSelected.projectLink} state={{ category: aSelectedSection, projectSelected: firstRun ? filteredPortfolio[0] :projectSelected }}>
          <PortfolioPreviewItem id={filteredPortfolio[0].id} url={firstRun  ?filteredPortfolio[0].photo :projectSelected.photo} title={firstRun ? filteredPortfolio[0].title : projectSelected.title}  />
        </Link>
      </div>
    );
    }catch (ex){

    }

    return "";
  };
  const divRef = useRef(null);
  const scrollToTop = () => {
    // Accessing the current property of the ref to get the DOM node
    const divElement = divRef.current;
    // Scroll the div to the top
    if (divElement) {
      divElement.scrollTop = 0;
    }
  };
//download project categories
const [projectCategoryTitles, setProjectCategoryTitles] = useState({});

useEffect(() => {
  fetch('https://app.mrakhudu.com/endpoints/data/fetchProjectCategories.php')
    .then(response => response.json())
    .then(content_data =>{ setData(content_data)
      console.log("Results: "+JSON.stringify(content_data))
      const titles = {};
    content_data.forEach(element => {
      if (element.is_visible == "1")
        titles[element.category_code] = element.title;
    });
    setProjectCategoryTitles(titles);
        
    })
    .catch(error => console.error('Error fetching data:', error));
}, []);

useEffect(() => {
  if (Object.keys(projectCategoryTitles).length > 0) {
    downloadProjects(projectCategoryTitles);
  }
}, [projectCategoryTitles]);
//download projects
const [content_data, setData] = useState(null);
const [projectsData, setProjectsData] = useState([]);
const [projectsPreviewData, setProjectPreview] = useState([]);

function downloadProjects(projectCategoryTitles){

  var url = 'https://app.mrakhudu.com/endpoints/data/fetchProjects.php?category='+ projectCategoryTitles[category].replace(" ", "%20")
  console.log("URL "+url)
  fetch(url)
  .then(response => response.json())
  .then(content_data =>{ setData(content_data)
    console.log("Results 2: "+JSON.stringify(content_data))
    var data_raw= []
    try{
    
      var  id = 1;
      content_data.forEach(project => {   
        project['id']= id         
        project['photo'] = "https://admin.mrakhudu.com/sites/default/files/"+project.imageURL.replace("public://","")
        project['type'] = category
        project['caption'] = stripHtml(project.summary)
        project['code'] =  project.category_code
        project['startDate'] = project.project_dates_and_duration.split(" - ")[0]
        project['endDate'] = project.project_dates_and_duration.split(" - ")[1]
        project['technologies'] = project.programming_languages
        project['collaborators'] = project.collaborators
        project['projectLink'] =  project.projectLink
        data_raw.push(project)
        id = id+1
        });  
      }catch (ex){
        console.log("Error: "+ex)
      }
      console.log("RES"  + JSON.stringify(data_raw))
      
      setListPortfolio(data_raw); 
     
      setProjectsData(renderProjectsList(data_raw))
      setProjectPreview(renderProjectsPreviews(data_raw,selectedSection,true,projectSelected))

      console.log("test "+projectsData)

      setLoadPage(true)
    

  })
  .catch(error => console.error('Error fetching data:', error));
}

function stripHtml(html) {
  // Create a new div element
  const temporalDivElement = document.createElement("div");
  // Set the HTML content with the provided
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

const scrollToBottom = () => {
  // Accessing the current property of the ref to get the DOM node
  const divElement = divRef.current;
  // Scroll the div to the bottom
  if (divElement) {
    divElement.scrollTop = divElement.scrollHeight - divElement.clientHeight;
  }
};

  return (
    <div >
      <div id="content" style={{ display: showPortfolioSection ? 'block' : 'none' }}>
        <div className="pageTitle" id="porfolioPageTitle">
          <h1>PORTFOLIO</h1>
          <h4>COOL STUFF I'VE WORKED ON</h4>
        </div>
        <div id="pageLoader" style={{opacity: loadPage ? 0: 1}}>
      <Loading  ></Loading>
      </div>
        <br></br>
        <div className="row portolio-container" ref={divRef}>
            <div className="col-md-6">
             <div className="" >
                {/* Project List */}
                <Link to="/portfolio">
                <motion.div className={"button-portfolio"} 
                     whileHover={{ scale: 1.1, opacity: 0.6}}
                     whileTap={{
                       scale: 1.2,
                       borderRadius: "100%"
                     }}
                     onClick={() =>onClickPortfolioBack()} 
                    style={{color: 'white'}}>
                     <motion.div 
                    whileHover={{ scale: 1.1, opacity: 0.6}}
                    whileTap={{
                      scale: 1.2,
                      borderRadius: "100%"
                    }}
                    className="col-md-1 " style={{width: '30px'}}>
                      <img src={image_arrow_left} />
                    </motion.div>
                  </motion.div >
                  </Link>
                {projectsData}
              </div>
             </div>
             
             <div className="col-md-6 portfolio-sections-container" >                
                  <div>
                      <div className="row" >
                    {/* Project Preview */}
                   
                    <div className="col-md-12">
                    {projectsPreviewData}
                    </div>
                   
                  </div>
                  </div>
             </div>
             
        </div>
      </div>
                     
      
      {/*<div className="project-popup" style={{backgroundColor: 'white', color: 'black', 
      width: '90vw', height:'90vh', left: '50%', right: '50%', transform: 'translate(-50%, 5%)',
      zIndex: 4000, position:'fixed', display: showProjectDetails? 'block' : 'none'}}>
                     {/*<h4>{projectSelected.title == null ? "" :projectSelected.title}</h4>*
                     <h5>{projectSelected.type== null ? "" :projectSelected.type}</h5>
                     <div onClick={closePopup} style={{textAlign: "right", paddingRight: "20px"}}>Close</div>
                     <Iframe width='100%' height ='100%' url={projectSelected.link}/>
      </div>*/}
    </div>
  );
};

export default PortfolioProjectCategory;
