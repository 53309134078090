import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import List from "./List";
import { Link } from 'react-router-dom'
const Slider = ({ slides, showIndicators, showNavigation, interval, slideLimit, layout, fade, slide, click }) => {
  const headingCss = { backgroundColor: "rgba(51,51,51,0.86)", padding: "10px", width: "auto", display: "inline-block" }
  var carouselItems
  if (layout == "fullwidth") {
    carouselItems = slides.map((slide) => (
      <Carousel.Item interval={interval}>
        <img key={slide.id}
          className="d-block w-100"
          src={slide.photo}
          alt={slide.id}
        />
        <Carousel.Caption className="fullwidth-carousel-caption">
          <h2>{slide.title}</h2>
          <p>{slide.caption}</p>
          <List heading='Languages' headingClass="" list={slide.techlogies} ulClass="" liClass="" />
        </Carousel.Caption>
      </Carousel.Item>
    ))
  }
  else if (layout == "one-col"){
    carouselItems = slides.map((slide,index) => (
      <Carousel.Item key={index} >
         <Link style={{textDecoration : "none"}} 
         to={slide.code+"/"+slide.projectLink} 
         state={{ category: slide.category, projectSelected: slide }} >
        <div className="carousel-one-col" style={{cursor: 'pointer'}}>
          <div className="carousel-one-col-image">
            <img key={slide.id}
              className="d-block portolio-project-photo"
              src={slide.photo}
              alt={slide.id}
            />
          </div>
          <div className="carousel-one-col-caption">
            <Carousel.Caption>
              <p><strong>{slide.title} </strong>- <i>{slide.type}</i> - {slide.caption}</p>
            </Carousel.Caption>
          </div>
          
        </div>
        </Link>
      </Carousel.Item>
    
    ))
  }
  else { //two coll
    carouselItems = slides.map((slide) => (
      <Carousel.Item>
        <div className="row">
          <div className="col-md-6">
            <img key={slide.id}
              className="d-block portolio-project-photo"
              src={slide.photo}
              alt={slide.id}
            />
          </div>
          <div className="col-md-6">
            <Carousel.Caption>
              <h3>{slide.title}</h3>
              <p>{slide.caption}</p>
              <List heading='Languages' headingClass="" list={slide.technologies} ulClass="" liClass="" />
            </Carousel.Caption>
          </div>
        </div>
      </Carousel.Item>
    ))
  }
  return (
    <Carousel controls={showNavigation} indicators={showIndicators} interval={interval} fade={fade} slide={slide}>
      {carouselItems}
    </Carousel>
  );
};

export default Slider;
