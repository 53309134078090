import React from "react";
import image_gabz from '../img/gabz.png';
import image_arrow_home from '../img/arrow-home.png';
import NamesHolder from "./NamesHolder";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import HomeNavigationList from "./HomeNavigationList";

const Home = ({visibility,functions,pageList}) => {
  const milliseconds = 1000;
    const [loadPage, setLoadPage] = useState(true);
    const imageAnimationDuration = 1;
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setLoadPage(true);
      },1); //  10 seconds
  
  
      return () => {
        clearTimeout(loadPage);
      };
    }, []);
    const showHomeSection = true//visibility.showHome;
  //show intro
  function navigateIntro(showIntroSection) {
    console.log("Navigate To Intro")
  functions.navigateIntro();
  console.log(showIntroSection)
  }
  return (
    <div >
      <div id="content" style={{display: showHomeSection ? 'block' : 'none' }}>
        <div className="row">
        
            <div className="col-md-12" style={{margin:'auto'}}>
            <motion.div  className="homeBox" style={{margin:'auto'}}
             animate={{ y: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }} 
             transition={{ duration: imageAnimationDuration }} 
             initial={{ y: -500, opacity:0 }} >
            <HomeNavigationList functions={functions} visibility={visibility} pageList={pageList}></HomeNavigationList>
            </motion.div>
            </div>
            
        </div>
      </div>
    </div>
  )
}

export default Home