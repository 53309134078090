import React from 'react'
import Loading from './Loading'
import ReCAPTCHA from 'react-google-recaptcha';
import image_contact from '../img/contact.png';
import mr_chat_icon from '../img/mr_chat_icon.png';
import x_chat_icon from '../img/x_chat_icon.png';
import { useEffect, useState,useRef } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const Contact = ({ visibility, functions, pageList, data, resetDrag,serviceSelected,refreshPage }) => {
  const location = useLocation();
  const [paramValue, setParamValue] = useState(null);
  const [loadPage, setLoadPage] = useState(false);
  const buttonAnimationDuration = 5;
  const imageAnimationDuration = 2;
  const arrowAnimationDuration = 1;
  const textAnimationDuration = 2;
  const formSubmitURL = "https://app.mrakhudu.com/endpoints/forms/contact/submit.php"
  const chatMessage = "Hi! Ready to make magic together? Share your details below. :)"
  const [selectedValue, setSelectedValue] = useState('value2'); // State for the selected value
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  function openSendMessage(e) {
    //show message form using Router
    console.log("Clicked!")
  }

  if (refreshPage ){
    setLoadPage(false)
    console.log("Refresh!")
    functions.setRefreshPage(false)
  }
 // Add state to determine if it's a touch device
 const [isTouchDevice, setIsTouchDevice] = useState(false);
  // Detect if it's a touch device
  useEffect(() => {
    const onTouchStart = () => setIsTouchDevice(true);
    const onHoverStart = () => setIsTouchDevice(false);

    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('mouseenter', onHoverStart);

    return () => {
        document.removeEventListener('touchstart', onTouchStart);
        document.removeEventListener('mouseenter', onHoverStart);
    };
    }, []);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    //console.log(event)
    // Fetch input values
    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      subject: event.target.subject.value,
      message: event.target.message.value,
      // Add reCAPTCHA validation if needed
    };
    
    if (!recaptchaValue) {
      // Show an error message or handle the lack of reCAPTCHA validation
      alert('Captcha validation failed!');
      return;
    }
    // Validate form inputs (add your own validation logic here)
    var url = formSubmitURL+"?name="+formData.name+"&email="+formData.email+"&subject="+formData.subject+"&message="+formData.message
    // Submit form data to the server
    console.log("URL:" +url)
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    
      if (response.ok) {
        // Handle successful form submission (e.g., show success message)
       
        // Assuming the server response is in JSON format
        const data = await response.text();
        if (data.includes("Message has been sent")){
          alert('Message successfully! We shall get back to you soon!');
          window.location = "/"
    
        }else{

          alert('Form submission failed.');
        }
        console.log(data);
      } else {
        // Handle form submission error
        const errorData = await response.text(); // or response.json() if the server sends JSON in case of errors
        console.log(errorData);
        alert('Form submission failed.');
      }
    } catch (error) {
      // Handle network errors or other issues
      console.error('Error submitting form:', error);
      alert('Error submitting form:', error);
    }
    
  };
  var aServiceSelected = ""

  useEffect(() => {
    // Assuming you want to parse the current window URL
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get("service"); // Replace 'your ParamName' with the name of your parameter
  setParamValue(value);
  }, []);

  if (serviceSelected[1] !=null && serviceSelected[1] != undefined){
    console.log("Service clicked "+serviceSelected[0] + " - "+serviceSelected[1])
    switch (serviceSelected[1]){

      case "DATA AANALYTIS": aServiceSelected = "Data Analytics"; break;
      case "MOBILE APPLICATIONS": aServiceSelected = "Mobile Application Development"; break;
      case "WEBSITES": aServiceSelected = "Website Development"; break;
      case "DESKTOP APPLICATIONS": aServiceSelected = "Desktop Application Development"; break;
      case "API DEVELOPMENT": aServiceSelected = "API Development"; break;
      case "AUTOMATION": aServiceSelected = "Automation"; break;
    }
  }else{
     // assuming your route has :category/:project
    console.log("Page Route: " + paramValue )
    const serviceCodeSelected = paramValue
    //get corresponsing item from services list

    switch (serviceCodeSelected){
      case "analytics": aServiceSelected = "Data Analytics"; break;
      case "mobile": aServiceSelected = "Mobile Application Development"; break;
      case "desktop": aServiceSelected = "Desktop Application Development"; break;
      case "api": aServiceSelected = "API Development"; break;
      case "web": aServiceSelected = "Website Development"; break;
      case "automation": aServiceSelected = "Automation"; break;
    }

  }
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
 const contactSubject = ["Data Analytics","Mobile Application Development","Website Development","Desktop Application Development","API Development","Automation","Music","Collaboration","UI/UX Design","Employment Opportunites","Other"]
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoadPage(true);
    }, 1); //  10 seconds
    return () => {
      clearTimeout(loadPage);
    };
  }, []);
  
  var choices = contactSubject.map((element) => (
    <option value={element}>{element}</option>
  ));
  const showContactSection = true//visibility.showContact;
  const pageSubtitle = "I'D LIKE TO TALK ABOUT..."
  const formRef = useRef(null);

  const resetForm = () => {
    // Accessing the form element and resetting its fields
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  return (
    <div id='contact' className='region region-contact'>
      <div id="content" style={{ display: showContactSection ? 'block' : 'none' }}>
      <div className="pageTitle" id="contactBoxTitle">
          <h1>LET'S TALK</h1>
          <h4>{pageSubtitle}</h4>
        </div>
        
        <div className="row">
          <div className="col-md-1">

          </div>
          <div className="col-md-5" style={{left: '10px'}}>
            <motion.div className="contactBox" style={{display: isTouchDevice? 'none' : 'block'}} 
            animate={{ y: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }}
              transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity: 0 }} >
              <div className='contactBoxInner'>
                  <motion.div className='contactBoxTitle' animate={{ y: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }}
              transition={{ duration: textAnimationDuration }}
              initial={{ y: -500, opacity: 0 }}>
                  <h1  style={{fontSize: '90px'}}>LET'S</h1>
                  <h1 style={{fontSize: '90px',
                      color: 'transparent',
                      WebkitTextStroke: '5px white',
                      WebkitTextFillColor: 'transparent'}}>TALK</h1>
                      </motion.div>
                  <br></br><br></br><br></br><br></br>
                  <h2 style={{textAlign: 'left'}}>A</h2>
                  <h2 style={{textAlign: 'left',
                      color: 'transparent',
                      WebkitTextStroke: '3px white',
                      WebkitTextFillColor: 'transparent'}}>RE BUE</h2>
                  <h3 style={{textAlign: 'left'}}>PARLONS</h3>
                  <h5 style={{textAlign: 'left'}}>Kōrero tāua</h5>
                  <h5 style={{textAlign: 'left'}}>Tuzungumze</h5>
                </div>
            </motion.div>
          </div>
          <div className="col-md-6">
            <motion.div className="contactFormBox" animate={{ y: loadPage ? 0 : -500, opacity: loadPage ? 1 : 0 }}
              transition={{ duration: imageAnimationDuration }} initial={{ y: -500, opacity: 0 }} >
                <div className='contactFormTitle' style={{ display: isTouchDevice? 'none' :'block', color: '#6CFF6C' ,textAlign: 'left', marginLeft: '50px'}}>
                  {pageSubtitle}</div>
                <div style={{position: 'relative'}}>
                    <div className='contactUsSubtitle' style={{position: 'absolute'}}>
                      <span>{chatMessage}</span>
                      <img style={{width:'40px',height:'40px', right: '-10px',position: 'absolute', top: '10px'}} src={mr_chat_icon}/> 
                    </div>
                </div>
                <form id='contactForm' onSubmit={handleSubmit} ref={formRef}>
                <div style={{height: '300px'}} >
                  <div style={{position: 'relative'}}>
                <img src={x_chat_icon} style={{width:'40px',height:'40px', zIndex: 100,top: '3px',position: 'absolute'}}/>
                  <input type="text" required name='name' className='contactFormName' style={{position:'absolute',left:'30px',  width:'-webkit-fill-available'}} placeholder='Who should I be responding to? Your name?'/>
                  </div>
                  <div style={{position: 'relative'}}>
                  <input type="email" className='contactFormEmail' style={{position:'absolute',left:'30px',top:'60px',  width:'300px'}} required={true} name='email' placeholder='what@your.email.com'/>
                  </div>
                  <div style={{position: 'relative'}}>
                  <select  name='subject' style={{position:'absolute',left:'30px',top:'120px'}} value={aServiceSelected == "" ? contactSubject[serviceSelected[0]] : aServiceSelected}  required={true} placeholder='how can i help you?' >
                      {choices}
                  </select>
                  </div>
                <div style={{position: 'relative'}}>
                <textarea style={{position:'absolute',left:'30px',top:'180px',height: '100px',padding: '10px',
                width: '-webkit-fill-available', }} className='contactFormMessage'
                    type="longtext"
                    required={true}
                    name='message'
                    placeholder={contactSubject[serviceSelected[0]] != null
                      ? "Something like, Hi Wazzza, I would like to enquire about your " +
                        contactSubject[serviceSelected[0]] +
                        " services.\nWhat is your end goal? : * Please Type Here *"
                      : 'How can I be able to help you? Are you looking for a website? A developer? A friend to talk to?'}
                    
                  ></textarea>
                  </div>
                </div>
                <div id='submitButton' style={{ left: '10px',width: '-webkit-fill-available', marginBottom: '30px'}} className='row'>
                  <div className='col-md-6'>
                  <ReCAPTCHA style={{marginLeft: '20px'}} sitekey="6LeDgMwlAAAAAMwrc-J5UWZPa0toYyPmtBo2uOeX" 
                onChange={handleRecaptchaChange}/>
                  </div>
                  <div className='col-md-6 row' style={{textAlign: 'start', marginLeft: '30px', marginTop: '10px'}} >
                    <motion.button className='contactFormSubmit col-md-8'  whileHover={{ scale: 1.1, opacity: 0.6}}
                     whileTap={{
                       scale: 1.2,
                       borderRadius: "100%"
                     }} 
                     value="Send A Message" onClick={openSendMessage}>
                      <i class="material-icons" style={{fontSize: '15px'}}>&#xe163;</i> Send A Message
                     </motion.button> 
                     <motion.button type='reset' className='contactFormSubmit col-md-4'  whileHover={{ scale: 1.1, opacity: 0.6}}
                     whileTap={{
                       scale: 1.2,
                       borderRadius: "100%"
                     }} 
                     value="Reset" onClick={resetForm}>
                      <i class="material-icons" style={{fontSize: '15px'}}>&#xf053;</i> Reset
                     </motion.button> 
                     </div>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </div>


    </div>
  )

}

export default Contact